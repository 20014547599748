<script setup>
import { Link, useForm } from '@inertiajs/vue3'

const contact = useForm({
    name: '',
    whatsapp: '',
    email: '',
    subject: '',
    message: ''
})

const loading = ref(false)
const $toast = useToast()

const sendMail = () => {
    loading.value = true
    contact.post(route('contact'), {
        preserveScroll: true,
        onSuccess: () => {
            contact.reset();
            $toast.success('Obrigado por entrar em contnato, em breve retornaremos!')
        },

        onError: (e) => {
            const error = Object.values(e)[0]
            $toast.error(error)
        },

        onFinish: () => {
            loading.value = false
        }
    })
}

const services = [
    {
        title: {
            text: 'Multi-Atendimento',
            color: '#0099FF'
        },
        description: 'Em um único painel, é possível visualizar métricas e realizar administração de todos os atendentes e empresas',
        btn: {
            text: 'Mais Infos - Multi',
            path: '/multiatendimento'
        }
    },
    {
        title: {
            text: 'API para developers',
            color: '#0099FF'
        },
        description: 'Desenvolvedores que desejam criar ou integrar com o WhatsApp podem contar com documentação simplificada.',
        btn: {
            text: 'Mais Infos - API',
            path: '/api-whatsapp'
        }
    },
    {
        title: {
            text: 'BOX',
            color: '#00CC1F'
        },
        description: 'Conecte seu site ao WhatsApp com nosso sistema de captura de leads e comece a capturar leads hoje mesmo.',
        btn: {
            text: 'Mais Infos - BOX',
            path: '/box'
        }
    },
    {
        title: {
            text: 'NikiBot',
            color: '#0099FF'
        },
        description: 'Sua Assistente Virtual com Inteligência Artificial no WhatsApp!',
        btn: {
            text: 'Mais Infos - NikiBot',
            path: '/nikibot'
        }
    }
    /*{
        title: {
            text: 'SendZap',
            color: '#00CC1F'
        },
        description: 'Envie campanhas programadas para seus clientes de forma fácil, rápida e automatizada (WhatsApp).',
        btn: {
            text: 'Mais Infos - SendZap',
            path: '/sendzap'
        }
    },
    {
        title: {
            text: 'LKF - Link FREE',
            color: '#0099FF'
        },
        description: 'Conecte seu WhatsApp ao link da sua bio ou divulgação com nosso sistema. Gerencie galeria de produtos.',
        btn: {
            text: 'Mais Infos - EM BREVE',
            path: '#'
        }
    },
    {
        title: {
            text: 'Bot Atende',
            color: '#00CC1F'
        },
        description: 'Automação de atendimento para WhatsApp: melhore o atendimento ao cliente com nosso sistema.',
        btn: {
            text: 'Mais Infos - EM BREVE',
            path: '#'
        }
    }*/
]


nextTick(() => {
    
    $(document).ready(function ($) {

        $('.Modern-Slider').slick({
            autoplay: true,
            autoplaySpeed: 10000,
            speed: 600,
            slidesToShow: 1,
            slidesToScroll: 1,
            pauseOnHover: false,
            dots: true,
            pauseOnDotsHover: true,
            cssEase: 'linear',
            draggable: false,
            prevArrow: '<button class="PrevArrow"></button>',
            nextArrow: '<button class="NextArrow"></button>'
        })    
    })
})
</script>

<template>
    <HeadTitle title="Especialistas em WhatsApp: chatbots, integração e marketing." />

    <WaitingModal v-if="loading"/>

    <section class="main-banner header-text main-section" id="home">
        <div class="Modern-Slider">
            <!-- Item -->
            <div class="item item-1">
                <div class="img-fill">
                    <div class="text-content">
                        <h6 style="color: #00CCFF;">Sistemas para WhatsApp corporativo</h6>
                        <h4 style="color: #fff; font-family:Poppins; font-style: bold;">Multi-atendimento</h4>
                        <p style="color: #02F102; font-style: bold;">Vários atendentes em um único número de WhatsApp.
                            <mark class="mark">
                                O  sistema de multi-atendimento oferece diversas possibilidades,
                            </mark>
                            <mark class="mark">como
                                métricas, etiquetas, chatbot ilimitado 24h por dia e muito mais.</mark>
                        </p>
                        <Link 
                            href="/multiatendimento"
                            class="btn greenbtn"
                        >
                            PLANOS A PARTIR DE R$ 99,90 /mês (Mais informações)
                        </Link>
                    </div>
                </div>
            </div>
            <!-- // Item -->
            <!-- Item -->
            <div class="item item-2">
                <div class="img-fill">
                    <div class="text-content">
                        <h6 style="color: #00CCFF;">Sistemas para WhatsApp corporativo</h6>
                        <h4 style="color: #fff; font-family:Poppins; font-style: bold;">BOX para Sites</h4>
                        <p style="color: #02F102; font-style: bold;">Capture leads diretamente de seu site.
                            <mark class="mark">Conecte
                                seu site ao WhatsApp e ofereça uma experiência</mark>
                            <mark class="mark">de
                                atendimento ao cliente mais personalizada e eficiente.</mark>
                        </p>
                        <Link 
                            href="/box" 
                            class="btn greenbtn"
                        >
                            Mais informações
                        </Link>
                    </div>
                </div>
            </div>
            <!-- // Item -->
            <!-- Item -->
            <div class="item item-3">
                <div class="img-fill">
                    <div class="text-content">
                        <h6 style="color: #00CCFF;">Integre</h6>
                        <h4 style="color: #fff; font-family:Poppins; font-style: bold;">API para WhatsApp</h4>
                        <p style="color: #02F102; font-style: bold;">API para integrações junto ao WhatsApp
                            <mark
                                class="mark">Com
                                esse recurso, você pode divulgar novos produtos, fortalecer</mark>
                            <mark
                                class="mark">o
                                relacionamento com seus clientes, captar novos clientes, enviar alertas,</mark>
                            <mark
                                class="mark">enviar
                                códigos de acesso, lembrar seus clientes de pagamentos,</mark>
                            <mark
                                class="mark">enviar
                                informações importantes e muito mais.</mark>
                        </p>
                        <Link 
                            href="/api-whatsapp"
                            class="btn greenbtn"
                        >
                            Mais informações
                        </Link>
                    </div>
                </div>
            </div>
            <!-- // Item -->
        </div>
    </section>

    <section class="service-stripe">
        <section class="centralized">
            <div class="stripe-info">
                <h2>
                    Duvidas?
                </h2>
                <span>
                    Entre em contato agora com um de nossos especialistas.
                </span>
            </div>

            <button class="btn-hollow">Falar com atendente</button>
        </section>
    </section>


    <section class="tw-w-full tw-bg-white tw-py-[100px] main-section" id="benesses">
        <section class="centralized">
            <header class="tw-w-full tw-flex tw-flex-wrap tw-mt-8 tw-mb-[100px]">
                <h2 class="tw-w-full tw-text-[40px] tw-font-bold tw-text-center tw-mb-6">
                    WhatsCompany <span class="tw-text-green-wc">Benesses</span>
                </h2>

                <span class="tw-w-full tw-text-slate-600 tw-text-center">
                    OS BENEFÍCIOS EXCLUSIVOS SÃO IDEAIS PARA QUEM QUER ECONOMIZAR TEMPO EM ATENDIMENTO E
                    MARKETING.<br />
                    <strong>SISTEMAS DESENVOLVIDOS PELA WHATSCOMPANY:</strong>
                </span>
            </header>


            <section class="services">
                <article class="service" v-for="(service, key) in services" :key="key">
                    <h2 
                        :style="{
                            color: service.title.color,
                            fontStyle: 'bold',
                            fontFamily: 'Poppins'
                        }"
                    >
                        {{service.title.text}}
                    </h2>

                    <p>{{ service.description }}</p>

                    <Link :href="service.btn.path"
                        class="btn greenbtn">
                    {{ service.btn.text }}
                    </Link>
                </article>
            </section>
        </section>
    </section>

    <section class="tw-w-full tw-bg-[#007FFF] tw-py-[80px] tw-text-white">
        <section class="centralized achievements-wrap">
            <div class="achievements">
                <h2 class="tw-w-full tw-flex tw-flex-wrap tw-text-[35px] tw-items-center tw-mb-6">
                    <span class="tw-w-full tw-uppercase tw-text-[15px]">Nossas Conquistas 🏆</span>
                    <strong>Desde <span class="tw-text-[#00FF00]">2016</span></strong>
                </h2>

                <p>Agradecemos a todas as parcerias de revenda e varejo no mercado de tecnologia.</p>

                <p>Em 2016, a WhatsCompany foi pioneira no sistema de BOX para sites no Brasil.
                    Desde então, estamos sempre focados no crescimento e no compartilhamento de novas criações de
                    inovação com sistemas de comunicação e marketing.</p>

                <p class="tw-italic tw-mt-8">
                    <strong>Missão:</strong> Nossa missão é fornecer soluções inovadoras e confiáveis que ajudem nossos
                    clientes a ter sucesso em seus negócios.<br />
                    <strong>Visão:</strong> Ser a empresa líder em soluções de comunicação e marketing, reconhecida pela
                    excelência e satisfação de seus clientes.<br />
                    <strong>Valores:</strong> Inovação, comprometimento, transparência e ética.
                </p>
            </div>


            <div class="achievements-list">
                <div class="achievement">
                    <h2>7</h2>
                    <span>Paises Atendidos</span>
                </div>
                <div class="achievement">
                    <h2>44</h2>
                    <span>Agências credenciadas BR</span>
                </div>
                <div class="achievement">
                    <h2>1366</h2>
                    <span>Afiliados</span>
                </div>
                <div class="achievement">
                    <h2>15777</h2>
                    <span>Clientes Ativos</span>
                </div>
            </div>
        </section>
    </section>


    <section class="tw-w-full tw-bg-white tw-pt-[100px] main-section" id="partner">
        <div class="centralized">
            <!--- more-info --->
            <section class="tw-flex tw-justify-center tw-w-full tw-px-3 tw-mt-8">
                <section class="tw-w-full tw-flex tw-rounded-md tw-overflow-hidden tw-bg-slate-100">

                    <div class="more-info-img tw-hidden min-[1200px]:tw-flex"></div>

                    <div class="tw-w-full min-[1200px]:!tw-w-[60%] tw-p-12">
                        <span class="tw-w-full tw-text-slate-500 tw-uppercase">Agências</span>
                        <h2 class="tw-text-[30px] tw-font-bold tw-text-blue-wc tw-mb-3">
                            Parceiro <span class="tw-text-green-wc">WhiteLabel</span>
                        </h2>

                        <p class="tw-text-slate-600">
                            Revender em White Label é uma oportunidade de oferecer produtos ou serviços de qualidade,
                            sem precisar desenvolvê-los internamente.
                        </p>

                        <span class="tw-flex tw-w-full tw-text-slate-500 tw-uppercase tw-mt-4">Afiliados</span>
                        <h2 class="tw-text-[30px] tw-font-bold tw-text-blue-wc tw-mb-3">
                            Indique e <span class="tw-text-green-wc">Ganhe</span>
                        </h2>

                        <p class="tw-text-slate-600">
                            Ganhe uma renda extra revendendo nossos produtos e serviços. Receba uma comissão por cada
                            venda realizada. Cadastre-se agora e comece já.
                        </p>


                        <div class="tw-w-full tw-flex tw-flex-wrap tw-gap-2 tw-justify-center tw-py-8">
                            <Link 
                                href="/whitelabel"
                                class="btn greenbtn"
                            >
                                Quero ser whitelabel
                            </Link>

                            <Link 
                                href="/pravc"
                                class="btn greenbtn"
                            >
                                Quero ser afiliado
                            </Link>
                        </div>
                    </div>

                </section>
            </section>
        </div>
    </section>


    <section class="tw-w-full tw-flex tw-flex-wrap tw-pt-[80px] tw-pb-[100px] main-section" id="contact">
        <div class="centralized">
            <header class="tw-flex tw-flex-wrap tw-w-full tw-py-12 tw-justify-center">

                <h1 class="tw-text-blue-wc tw-text-[35px] tw-font-bold tw-mb-4">
                    Fale <span class="tw-text-green-wc">Conosco</span>
                </h1>

                <p class="tw-w-full tw-text-slate-500 tw-text-center">NOSSOS ESPECIALISTAS ESTÃO À DISPOSIÇÃO PARA
                    ATENDER-LO(A).</p>
            </header>

            <section class="tw-w-full tw-flex tw-flex-wrap tw-gap-5 tw-bg-[#007FFF] tw-rounded-md tw-p-12">

                <div class="tw-w-full tw-grid tw-grid-cols-1  min-[800px]:tw-grid-cols-3 tw-gap-5">
                    <BaseInput 
                        placeholder="Nome" 
                        v-model="contact.name"
                    />

                    <BaseInput 
                        placeholder="Email" 
                        v-model="contact.email"
                    />

                    <BaseInput 
                        placeholder="Whatsapp" 
                        input-mask="(##) #####-####"
                        v-model="contact.whatsapp"
                    />
                </div>

                <BaseInput 
                    placeholder="Assunto" 
                    v-model="contact.subject"
                />

                <TextareaInput 
                    placeholder="Mensagem" 
                    v-model="contact.message" 
                />


                <div class="tw-flex tw-w-full tw-justify-center tw-mt-4">
                    <button class="btn-hollow tw-rounded-md tw-text-white" @click="sendMail">Enviar email</button>
                </div>
            </section>
        </div>
    </section>
</template>


<style scoped>
.btn.greenbtn {
    font-family: Poppins, Arial, Helvetica, sans-serif !important;
    color: #555555 !important;
    text-transform:uppercase !important;
    @apply !tw-rounded-full !tw-px-6 !tw-py-3 !tw-bg-green-wc hover:!tw-bg-blue-wc tw-font-bold tw-text-[13px] !tw-whitespace-normal;
}
.services {
    @apply tw-w-full tw-grid tw-grid-cols-1 min-[700px]:tw-grid-cols-2 min-[1600px]:tw-grid-cols-4 tw-gap-4 tw-mb-12;
}

.service{
    @apply tw-w-full tw-p-8 tw-bg-slate-100 tw-rounded-md;
}

.service h2 {
    @apply tw-w-full tw-font-bold tw-text-[22px] tw-mb-2;
}

.service p {
    @apply tw-text-slate-600 tw-my-6;
}

.achievements-wrap {
    @apply tw-grid tw-grid-cols-1 min-[800px]:tw-grid-cols-2 tw-gap-3;
}

.achievements {
    @apply tw-w-full tw-pr-6
}
.achievements p{
    @apply tw-mb-3
}


.achievements-list {
    @apply tw-w-full tw-grid tw-grid-cols-1 min-[1200px]:tw-grid-cols-2 tw-gap-4;
}

.achievement{
    @apply tw-w-full tw-flex tw-flex-wrap tw-p-4 tw-bg-[#198CFE] tw-rounded-md tw-justify-center tw-content-center
}
.achievement h2{
    @apply tw-w-full tw-text-center tw-text-green-wc tw-font-bold tw-text-[35px]
}
.achievement span{
    @apply tw-font-bold tw-w-full tw-text-center tw-text-[18px]
}

.more-info-img{
    @apply tw-w-[40%] tw-aspect-[13/12] tw-relative 
    before:tw-absolute before:tw-top-0 before:tw-right-0 before:tw-w-[4px] before:tw-h-[40%] before:tw-bg-green-wc 
    after:tw-absolute after:tw-bottom-0 after:tw-right-0 after:tw-w-[4px] after:tw-h-[60%] after:tw-bg-blue-wc;
    
    background:#ccc url('/images/more-info.jpg');
    background-size:cover;
    background-repeat:no-repeat;
    background-position: center center;
}


.mark {
    color: #000; 
    display: inline-block; 
    margin-bottom: 3px; 
    padding-left: 5px; 
    padding-right: 5px; 
    border-radius: 5px 5px 5px 5px;
    background:white;
}
</style>